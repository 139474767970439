const getMobileOS = () => {
    const ua = navigator.userAgent
    if (/android/i.test(ua)) {
        return "Android"
    }
    else if (/iPad|iPhone|iPod/.test(ua)){
        return "iOS"
    }
    return "Other"
}

export const getBankIdLaunchUrl = (autoStartToken) => {
    const mobileOS = getMobileOS();
    let baseUrl="";
    switch (mobileOS) {
        case "Android":
        case "iOS":
            baseUrl = "https://app.bankid.com/";
            break;
        default:
            baseUrl = "bankid:///";
            break;
    }
    let url = `${baseUrl}?autostarttoken=${autoStartToken}&redirect=`;
    return url;
}