import { errorHandler } from "./fetchErrorHandler";

export const get=(url) =>{
   return fetch(url,
    {
        redirect: 'error',
        method:'GET',
        headers: {
            "Content-Type": "application/json"
        }
    })
    .then(errorHandler)
    .then(jsonResponse => {
        return jsonResponse;
    });
}

export const post=(url) =>{
    return fetch(url,
     {
         redirect: 'error',
         method:'POST',
         headers: {
             "Content-Type": "application/json"
         }
     })
     .then(errorHandler)
     .then(jsonResponse => {
         return jsonResponse;
     });
 }
 
 