export const errorHandler = (response) => {
    if (response.ok)
        return response.json();

    if (response.status >= 400 & response.status < 500) {
        return response
            .json()
            .then(json => {
                console.warn('Bad request : body', json);
                var error = Error(json.description); 
                error.developer_message = json.developer_message;
                error.serverError = json;
                throw error;
            });
    }

    return response
        .json()
        .then(json => {            
            if (json.developer_message) {
                console.error('Internal server error:', json.developer_message);
                var error = Error(json.developer_message); 
                error.serverError = json;
                throw error;
            } else {
                console.error('Internal server error:', json);
            }
            var error = Error(response.statusText);
            error.serverError = json;
            throw error;
        });
}