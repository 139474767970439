import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import Site from './scenes/__Site';
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<BrowserRouter basename={baseUrl}><Site/></BrowserRouter>
);

