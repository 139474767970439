import React, {useEffect} from "react";
import {Route, Routes} from "react-router-dom";
import {postMessage} from "../../messageHandler";
import ReactResizeDetector from "react-resize-detector";

import Signature from "../_Signature";
import "./typography.scss";
import "./grid.scss";
import "./styles.css";

export default function Site(props) {
    const _onResize = () => {
        if (window.self !== window.top) {
            const d = document,
                documentElement = d.documentElement,
                body = d.getElementsByTagName("body")[0],
                height = body.clientHeight || documentElement.clientHeight;

            postMessage.resize(height);
        }
    }

    useEffect(() => {
        _onResize();
    }, [_onResize]);

    return (
        <div className="site">
            <Routes>
                <Route path="/signatures/:signatureId" element={<Signature {...props} />}/>
            </Routes>
            <ReactResizeDetector
                handleWidth={true}
                handleHeight={true}
                onResize={_onResize.bind(this)}/>
        </div>
    );
}