import React, { useEffect, useState } from "react";
import 'whatwg-fetch';
import urls from "../../utils/urls";
import {get,post} from "../../utils/apiHandler";
import t from "../../translation";
import signatureStatus from "../../utils/signatureStatus";
import { getBankIdLaunchUrl } from "./urlHelpers";
import SignatureTestModeIndicator from "../SignatureTestModeIndicator";
import { errorHandler } from "../../utils/fetchErrorHandler";
import "./styles.css";
import { useParams } from "react-router-dom";
import QrDevice from "./qrDevice";
import SameDevice from "./sameDevice";

export default function Signature(props) {
    const { signatureId } = useParams();

    const [qrCodeSrc, setQrCodeSrc] = useState(null);
    const [qrSelected, setQrSelected] = useState(true);
    const [autoStartToken,setAutoStartToken] = useState("");
    const [qrCodeTimeout, setQrCodeTimeout] = useState(null);
    const [orderRef, setOrderRef] = useState("");
    const [testMode, setTestMode] = useState(false);
    const [error, setError] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [status, setStatus] = useState(signatureStatus.readyForSigning);

    const isMobile = ()=> {
        const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
        return regex.test(navigator.userAgent);
      }
      
    const refreshPage = (event) => {
        event.preventDefault();
        setQrCodeSrc(null);
        setAutoStartToken("");
        clearTimeout(qrCodeTimeout);
        setQrCodeTimeout(null);
        setOrderRef("");
        setError("");
        setLoaded(false);
        setStatus(signatureStatus.readyForSigning);
        init();
    }


    useEffect(() => {
        if (!qrSelected && qrCodeTimeout !== null) {
            window.clearTimeout(qrCodeTimeout)
        }
        else if (qrSelected && orderRef!== "") {
            pollQrCode(orderRef);
        }
    }, [qrSelected])

    useEffect(() => {
        if (status === signatureStatus.canceled) {
            window.clearTimeout(qrCodeTimeout)
        }
    }, [status])

    useEffect(() => {
        if(isMobile()){
            setQrSelected(false);
            return;
        }
        if (status == signatureStatus.readyForSigning && orderRef !== "") {
            pollQrCode(orderRef)
        }
    }, [status,orderRef])


    useEffect(() => {
           init() 
    }, []);




    const init = () => {
        post(urls.postSign(signatureId)) //have to refactor routing and use standard because current impl doesnt pass on match on props.       
            .then(jsonResponse => {
                var testMode = jsonResponse.is_test_mode;
                setTestMode(testMode);
                setStatus(jsonResponse.readyForSigning);
                setLoaded(true)
                let orderRef = jsonResponse.order_ref;
                setOrderRef(orderRef);
                pollCollect(orderRef);
                setAutoStartToken(jsonResponse.auto_start_token);


            })
            .catch(err => {
                setLoaded(true);
                setError(err);
                setStatus(signatureStatus.canceled);
            });

    }


    const setToCancelled = () => {
        setStatus(signatureStatus.canceled);

    }
    const pollCollect = (orderRef) => {
        get(urls.collectStatus(orderRef)).then(
            response => {
                let p = orderRef;
                let progressStatus = response.status;
                if ([signatureStatus.signing, signatureStatus.readyForSigning].indexOf(progressStatus) !== -1) {
                    setStatus(progressStatus);
                    window.setTimeout(() => {
                        pollCollect(p);
                    }, 1000);
                }
                else if (progressStatus === signatureStatus.completed) {
                    console.log("SIGNED COMPLETE");
                    setStatus(signatureStatus.completed);

                    const params = new URLSearchParams(window.location.search);
                    if (params.get("onSignedUrl") != null) {
                        window.location.href = params.get("onSignedUrl");
                    }
                    else {
                        console.log("Missing onSignedUrl param");
                    }
                }
                else if (progressStatus === signatureStatus.failed) {
                    console.log("failed");
                    setToCancelled();
                }
                else {
                    console.log("cancelled");
                    setToCancelled();
                    //failed... Redirect to error, or show failed info.
                }
            })
            .catch(err => {
                setError(err)
                setToCancelled();
            });

    }


    const pollQrCode = (orderRef) => {
        console.log("poll")
        if (status === signatureStatus.canceled) {
            return;
        }
        get(urls.getQrCode(orderRef), 
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                },
                redirect: 'error'
            })
            .then(jsonResponse => {
                let qrCodeSrc = jsonResponse.qr;
                setQrCodeSrc(qrCodeSrc);
                let qrTimeout = window.setTimeout(() => {
                    pollQrCode(orderRef)
                }, 1000);
                setQrCodeTimeout(qrTimeout);
            })
            .catch(err => {
                console.log("error getting qrcode")
            });

    }

    const onChooseSameDevice = (e) => {
        e.preventDefault();
        //launchBankId(getBankIdLaunchUrl(autoStartToken));
        setQrSelected(false);
    }

  

    const onChooseOtherDevice = (e) => {
        e.preventDefault();
        setQrSelected(true);
    }

 
    return loaded? 
        <div className="signature-container">
            <SignatureTestModeIndicator testMode={testMode} signatureId={signatureId} {...props}/>
            {
            <div>
                <div className="signing-in-progress-container">
                    <h1 className="signing-in-progress__header">
                        {
                            t`SIGNING_IN_PROGRESS_HEADER`
                        }
                    </h1>
                    <div className="signing-in-progress__content">
                        {
                            qrSelected ?
                                    <QrDevice
                                    qrCodeSrc={qrCodeSrc}
                                    status={status}
                                    error={error}
                                    refreshPage={refreshPage}
                                    onChooseSameDevice={onChooseSameDevice} />

                                :
                                <SameDevice
                                    status={status}
                                    error={error}
                                    refreshPage={refreshPage}
                                    onChooseOtherDevice={onChooseOtherDevice}
                                    autoStartToken={autoStartToken}
                                    />
                        }
                    </div>
                </div>
            </div>
            }
        </div>
        : <React.Fragment />;
}
