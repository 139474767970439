let _partnerDomain = null;
let _orderReferences = null;

const _postMessage = (data) => {
  if (window.self !== window.top) {
      window.top.postMessage(data,"*");
  }
}

export const setPartnerData = (partnerDomain, orderReferences) => {
  _partnerDomain = partnerDomain;
  _orderReferences = orderReferences;
}


export default {
  postMessage: function(data, domain) {
    window.top.postMessage(data, domain);
  },
  checkoutComplete: () => {
    _postMessage({
      type: "checkoutComplete",
      value: _orderReferences
    });
  },
  checkoutRedirect: () => {
    _postMessage({
      type: "checkoutRedirect",
      value: _orderReferences
    })
  },
  checkoutCancel: () => {
    _postMessage({
      type: "checkoutCanceled",
      value: _orderReferences
    });
  },
  scrollToTop: () => {
    _postMessage({
      type: "scrollToTop",
      value: ""
    });
  },

  resize: height => {
    _postMessage({
      type: "resize",
      value: height
    });
  },
};
