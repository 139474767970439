/* @flow */

import React, {  useState } from "react";
import { Button } from "../../components";
import urls from "../../utils/urls";

import redCornerIcon from "../../assets/red-corner.svg";
import "./style.css";

type Props = {
  testMode: bool,
  hasClickedFakeSignatureButton: bool
};

export default function SignatureTestModeIndicator(props) {
  const { testMode, signatureId} = props;
  const [hasClickedFakeSignatureButton, setHasClickedFakeSignatureButton] = useState(false);
  const createFakeSignature= (signatureId) => {
    fetch(
      urls.postFakeSignature(signatureId), 
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        }
      });
      setHasClickedFakeSignatureButton(true);
    }

    const clickButton = () => {
      createFakeSignature(signatureId);
    }
    const simulateSignatureButtonLabel = hasClickedFakeSignatureButton 
      ? "Signing completed" 
      : "Simulate signing with BankID";

      if (!testMode) {
        return null;
      }
  
    return (
    <div id="test-mode-indicator" className="test-mode-indicator__wrapper">
      <div className="test-mode-indicator__container">
        <img className="test-mode-indicator__red-corner" src={redCornerIcon} />
        <h4 className="test-mode-indicator__header">{"Testmode is enabled"}</h4>
        <p className="signature-test-mode-indicator__text">
                  {"Simulate a BankID signing by pressing the button."}<br/>
                  {"To simulate a canceled BankID signing just wait 30 seconds."}
                </p>
                <Button
                  id="fake-sign-agreement"
                  className="signature-test-mode-indicator__fake-signature-button"
                  onClick={clickButton}
                  label={simulateSignatureButtonLabel}
                  disabled={hasClickedFakeSignatureButton} />
                <div className="signature-test-mode-indicator__clear "></div>
      </div>
    </div>
    );
  }

