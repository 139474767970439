import React from "react";
import t from "../../translation";
import { getBankIdLaunchUrl } from "./urlHelpers";
import signatureStatus from "../../utils/signatureStatus";
import { Spinner } from "../../components";

const SameDevice = (props) => {
    const { autoStartToken, onChooseOtherDevice, status, error, refreshPage } = props;

    const showSpinner = status === signatureStatus.readyForSigning || status === signatureStatus.signing
    ? <Spinner />
    : null;

    const generateErrorText = err => {

        if (err && err.serverError && err.serverError.error_code) {
            return t([err.serverError.error_code]);
        }

        return "";
    };

    const launchBankId = (url)  => {
        window.location.href = url;
    }
    const generateStatusText = (status, cancelMessage) => {
        switch (status) {
            case signatureStatus.readyForSigning:
                return t`BANK_ID_STATUS_TEXT_READY_FOR_SIGNING`;
            case signatureStatus.signing:
                return t`Inväntar signering...`;
            case signatureStatus.canceled:
                return (
                    <span>
                        {t`Signeringen har blivit avbruten`}
                        {cancelMessage ? (<span className='signing-in-progress_cancel_message'><br />{cancelMessage}</span>) : ""}
                        <br />
                        <a href="#" onClick={refreshPage} className="signing-in-progress__content__link">Försök igen</a>
                    </span>
                );
            case signatureStatus.completed:
                return t`Signering är klar!`;
            default:
                return "...";
        }
    };
    return (
        <div className="same-device-container">
            <div>
                {
                    status !== signatureStatus.canceled &&
                    <a className="start-bankid-link" href={getBankIdLaunchUrl(autoStartToken)} rel="noreferrer" target="_blank">Starta bankid</a>
                //    <button className="launch-button" onClick={() => launchBankId(getBankIdLaunchUrl(autoStartToken))}>Starta bankid...</button>
                }
                <p id="signing-status-message" className="signing-in-progress__content__status-message">
                    {generateStatusText(status, generateErrorText(error))}
                </p>
                {showSpinner}
                {
                    status !== signatureStatus.canceled &&
                    <div className="info">
                        <h2>Har du bank-id på annan enhet?</h2>
                        <button className="linkbutton" onClick={(e) => onChooseOtherDevice(e)}>Visa QR-kod för inloggning</button>
                    </div>
                }
            </div>
        </div>



    )

}

export default SameDevice;