import React from "react";
import QRCode from "react-qr-code";
import t from "../../translation";
import signatureStatus from "../../utils/signatureStatus";

const QrDevice =(props)=>{
    const {refreshPage, status, error,onChooseSameDevice} = props;
    const generateStatusText = (status, cancelMessage) => {
        switch (status) {
            case signatureStatus.readyForSigning:
            case signatureStatus.signing:
                return t `Inväntar signering...`;
            case signatureStatus.canceled:
                return (
                    <span id="canceled-signing-label">
                        {t`Signeringen har blivit avbruten`}
                        {cancelMessage ? (<span className='signing-in-progress_cancel_message'><br />{cancelMessage}</span>) : ""}
                        <br/>
                        <a href="#" onClick={refreshPage} className="signing-in-progress__content__link">Försök igen</a>
                    </span>
                );
            case signatureStatus.completed:
                return <span data-testid='signing-complete-text'>Signering är klar!</span>;
            default:
                return "...";
        }
    };

    const generateErrorText = err => {
        
        if (err && err.serverError && err.serverError.error_code) {            
            return t([err.serverError.error_code]);
        }

        return "";
    };

    return(
        <React.Fragment>
            <div className="qr-container">
                <div>
                {
                        (props.qrCodeSrc !== null && status!==signatureStatus.canceled) &&
                    <div>
                    <QRCode value={props.qrCodeSrc} />
                    <ul>
                        <li>
                            Öppna appen för BankID.
                        </li>
                        <li>
                            Tryck på QR-symbolen i appen för BankID.
                        </li>
                        <li>
                            Rikta kameran mot QR-koden.
                        </li>
                        <li>
                            Följ instruktionerna i appen för att logga in.
                        </li>
                    </ul>

                    </div>
                }

                    <p id="signing-status-message" className="signing-in-progress__content__status-message">
        {generateStatusText(status, generateErrorText(error))}                        
        </p>


                    {
                        status !== signatureStatus.canceled &&
                    <div className="info">
                        <h2>Har du bank-id på den här enheten?</h2>
                        <button className="linkbutton" onClick={(e) => onChooseSameDevice(e)}>Starta bankid för inloggning</button>

                    </div>
                    }
                </div>

            </div>
        


    </React.Fragment>


    )

}

export default QrDevice;